// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconUserPlus,
    IconGraph,
    IconGrill,
    IconContainer,
    IconDashboard,
    IconDeviceAnalytics,
    IconUsers,
    IconUserCheck
} from '@tabler/icons';

const icons = {
    IconGrill,
    IconContainer,
    IconDashboard,
    IconDeviceAnalytics,
    IconUsers,
    IconUserCheck,
    IconGraph,
    IconUserPlus
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const SuperAdminDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'default',
            title: <FormattedMessage id="default" />,
            type: 'item',
            url: '/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        // {
        //     id: 'dashboard2',
        //     title: <FormattedMessage id="Meta Balance" />,
        //     type: 'item',
        //     url: '/dashboard2',
        //     icon: icons.IconGraph,
        //     breadcrumbs: false
        // },
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/payout-dashboard',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'payout1-dashboard',
            title: <FormattedMessage id="Payout Dashboard" />,
            type: 'item',
            url: '/payout1-dashboard',
            icon: icons.IconGrill,
            breadcrumbs: false
        },
        {
            id: 'payin-levels',
            title: <FormattedMessage id="Merchant Levels" />,
            type: 'item',
            url: '/payin-levels',
            icon: icons.IconContainer,
            breadcrumbs: false
        },
        {
            id: 'merchant',
            title: <FormattedMessage id="merchant" />,
            type: 'item',
            url: '/dashboard/merchant',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'merchant-user',
            title: <FormattedMessage id="Merchant User" />,
            type: 'item',
            url: '/dashboard/merchant-user',
            icon: icons.IconUserPlus,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: <FormattedMessage id="user" />,
            type: 'item',
            url: '/dashboard/user',
            icon: icons.IconUsers,
            breadcrumbs: false
        }
    ]
};
export const AdminDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'default',
            title: <FormattedMessage id="default" />,
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        // {
        //     id: 'dashboard2',
        //     title: 'Meta Balance',
        //     type: 'item',
        //     url: '/dashboard2',
        //     icon: icons.IconGraph,
        //     breadcrumbs: false
        // },
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/payout-dashboard',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'payout1-dashboard',
            title: <FormattedMessage id="Payout Dashboard" />,
            type: 'item',
            url: '/payout1-dashboard',
            icon: icons.IconGrill,
            breadcrumbs: false
        },
        {
            id: 'merchant',
            title: <FormattedMessage id="Merchants" />,
            type: 'item',
            url: '/dashboard/merchant',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'merchant-user',
            title: <FormattedMessage id="Merchant User" />,
            type: 'item',
            url: '/dashboard/merchant-user',
            icon: icons.IconUserPlus,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/dashboard/user',
            icon: icons.IconUsers,
            breadcrumbs: false
        }
    ]
};

export const SubAdminDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'default',
            title: <FormattedMessage id="default" />,
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        // {
        //     id: 'dashboard2',
        //     title: 'Meta Balance',
        //     type: 'item',
        //     url: '/dashboard2',
        //     icon: icons.IconGraph,
        //     breadcrumbs: false
        // },
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/payout-dashboard',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'payout1-dashboard',
            title: <FormattedMessage id="Payout Dashboard" />,
            type: 'item',
            url: '/payout1-dashboard',
            icon: icons.IconGrill,
            breadcrumbs: false
        },
        {
            id: 'merchant',
            title: <FormattedMessage id="Merchants" />,
            type: 'item',
            url: '/dashboard/merchant',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/dashboard/user',
            icon: icons.IconUsers,
            breadcrumbs: false
        }
    ]
};

export const AgentDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'default',
            title: <FormattedMessage id="default" />,
            type: 'item',
            url: '/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'merchant-user',
            title: <FormattedMessage id="Merchant User" />,
            type: 'item',
            url: '/dashboard/merchant-user',
            icon: icons.IconUserPlus,
            breadcrumbs: false
        },
        {
            id: 'merchant',
            title: <FormattedMessage id="merchant" />,
            type: 'item',
            url: '/dashboard/merchant',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        }
    ]
};

export default SuperAdminDashboard;
