// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconArrowDownLeft,
    IconArrowDownLeftCircle,
    IconArrowUpRight,
    IconArrowUpRightCircle,
    IconBuildingBank,
    IconFileInvoice,
    IconLogin,
    IconAdjustments,
    IconBuilding,
    IconBuildingEstate,
    IconBuildingSkyscraper
} from '@tabler/icons';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconArrowDownLeft,
    IconArrowDownLeftCircle,
    IconArrowUpRight,
    IconArrowUpRightCircle,
    IconBuildingBank,
    IconFileInvoice,
    IconLogin,
    IconAdjustments,
    IconBuilding,
    IconBuildingEstate,
    IconBuildingSkyscraper
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const SuperAdminPayment = {
    id: 'payin & payout',
    title: <FormattedMessage id="payin & payout" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'transaction',
            title: <FormattedMessage id="transaction" />,
            type: 'item',
            url: '/payment/transaction',
            icon: icons.IconArrowDownLeft,
            breadcrumbs: false
        },
        {
            id: 'ss transaction',
            title: <FormattedMessage id="ss transaction" />,
            type: 'item',
            url: '/payment/ss-transaction',
            icon: icons.IconArrowDownLeftCircle,
            breadcrumbs: false
        },
        {
            id: 'payout',
            title: <FormattedMessage id="payout" />,
            type: 'item',
            url: '/payment/payout',
            icon: icons.IconArrowUpRight,
            breadcrumbs: false
        },
        {
            id: 'PayoutCOntrol',
            title: <FormattedMessage id="Payout Control" />,
            type: 'item',
            url: '/payment/payout-control',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'payout w client',
            title: <FormattedMessage id="payout w client" />,
            type: 'item',
            url: '/payment/payout-w-client',
            icon: icons.IconArrowUpRightCircle,
            breadcrumbs: false
        },
        {
            id: 'manual banks',
            title: <FormattedMessage id="manual banks" />,
            type: 'item',
            url: '/payment/bank',
            icon: icons.IconBuildingBank,
            breadcrumbs: false
        },
        {
            id: 'debit banks',
            title: <FormattedMessage id="Debit Banks" />,
            type: 'item',
            url: '/payment/debit-banks',
            icon: icons.IconBuildingSkyscraper,
            breadcrumbs: false
        },
        {
            id: 'payout banks',
            title: <FormattedMessage id="Payout Banks" />,
            type: 'item',
            url: '/payment/payout-banks',
            icon: icons.IconBuildingEstate,
            breadcrumbs: false
        },
        {
            id: 'bank transaction',
            title: <FormattedMessage id="bank transaction" />,
            type: 'item',
            url: '/payment/bank-transaction',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'bank login',
            title: <FormattedMessage id="bank login" />,
            type: 'item',
            url: '/payment/bank-login',
            icon: icons.IconLogin,
            breadcrumbs: false
        },
        {
            id: 'unclaim bank transaction',
            title: <FormattedMessage id="Unclaim UTR" />,
            type: 'item',
            url: '/payment/unclaim-bank-transaction',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'reconciliation',
            title: <FormattedMessage id="reconciliation" />,
            type: 'item',
            url: '/payment/reconciliation',
            icon: icons.IconAdjustments,
            breadcrumbs: false
        }
    ]
};
export const AdminPayment = {
    id: 'payin & payout',
    title: <FormattedMessage id="payin & payout" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'transaction',
            title: <FormattedMessage id="transaction" />,
            type: 'item',
            url: '/payment/transaction',
            icon: icons.IconArrowDownLeft,
            breadcrumbs: false
        },
        {
            id: 'ss transaction',
            title: <FormattedMessage id="ss transaction" />,
            type: 'item',
            url: '/payment/ss-transaction',
            icon: icons.IconArrowDownLeftCircle,
            breadcrumbs: false
        },
        {
            id: 'payout',
            title: <FormattedMessage id="payout" />,
            type: 'item',
            url: '/payment/payout',
            icon: icons.IconArrowUpRight,
            breadcrumbs: false
        },
        {
            id: 'PayoutCOntrol',
            title: <FormattedMessage id="Payout Control" />,
            type: 'item',
            url: '/payment/payout-control',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'payout w client',
            title: <FormattedMessage id="payout w client" />,
            type: 'item',
            url: '/payment/payout-w-client',
            icon: icons.IconArrowUpRightCircle,
            breadcrumbs: false
        },
        {
            id: 'manual banks',
            title: <FormattedMessage id="manual banks" />,
            type: 'item',
            url: '/payment/bank',
            icon: icons.IconBuildingBank,
            breadcrumbs: false
        },
        {
            id: 'bank transaction',
            title: <FormattedMessage id="bank transaction" />,
            type: 'item',
            url: '/payment/bank-transaction',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'bank login',
            title: <FormattedMessage id="bank login" />,
            type: 'item',
            url: '/payment/bank-login',
            icon: icons.IconLogin,
            breadcrumbs: false
        },
        {
            id: 'unclaim bank transaction',
            title: <FormattedMessage id="Unclaim UTR" />,
            type: 'item',
            url: '/payment/unclaim-bank-transaction',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'reconciliation',
            title: <FormattedMessage id="reconciliation" />,
            type: 'item',
            url: '/payment/reconciliation',
            icon: icons.IconAdjustments,
            breadcrumbs: false
        }
    ]
};

export const SubAdminPayment = {
    id: 'payin & payout',
    title: <FormattedMessage id="payin & payout" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'transaction',
            title: <FormattedMessage id="transaction" />,
            type: 'item',
            url: '/payment/transaction',
            icon: icons.IconArrowDownLeft,
            breadcrumbs: false
        },
        {
            id: 'payout',
            title: <FormattedMessage id="payout" />,
            type: 'item',
            url: '/payment/payout',
            icon: icons.IconArrowUpRight,
            breadcrumbs: false
        },
        {
            id: 'bank transaction',
            title: <FormattedMessage id="bank transaction" />,
            type: 'item',
            url: '/payment/bank-transaction',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'reconciliation',
            title: <FormattedMessage id="reconciliation" />,
            type: 'item',
            url: '/payment/reconciliation',
            icon: icons.IconAdjustments,
            breadcrumbs: false
        }
    ]
};
export const AgentPayment = {
    id: 'payin & payout',
    title: <FormattedMessage id="payin & payout" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'transaction',
            title: <FormattedMessage id="transaction" />,
            type: 'item',
            url: '/payment/transaction',
            icon: icons.IconArrowDownLeft,
            breadcrumbs: false
        },
        {
            id: 'bank transaction',
            title: <FormattedMessage id="bank transaction" />,
            type: 'item',
            url: '/payment/bank-transaction',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'payout',
            title: <FormattedMessage id="payout" />,
            type: 'item',
            url: '/payment/payout',
            icon: icons.IconArrowUpRight,
            breadcrumbs: false
        },
        {
            id: 'manual banks',
            title: <FormattedMessage id="manual banks" />,
            type: 'item',
            url: '/payment/bank',
            icon: icons.IconBuildingBank,
            breadcrumbs: false
        }
    ]
};

export default SuperAdminPayment;
