// material-ui
import { CardContent, Autocomplete, Card, Grid, TextField } from '@mui/material';

// project imports

import { gridSpacing } from 'store/constant';
import HoverDataCard from 'ui-component/cards/HoverDataCard';
import CurrencyRupee from '@mui/icons-material/CurrencyRupee';
import {
    fetchActiveMerchants,
    fetchDashboardData,
    fetchDashboardDataPayout,
    fetchPayinSummary,
    fetchPayoutDetailsByPG,
    fetchPayoutSummary,
    fetchTotalCollection,
    fetchBankAmount,
    fetchMerchSummary
} from 'utils/api';
import React, { useState } from 'react';
import Loader from 'ui-component/Loader';
import MainCard from 'ui-component/cards/MainCard';
import ReactApexChart from 'react-apexcharts';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ISTDate } from 'constant';

const currentDate = ISTDate;

// ==============================|| DEFAULT DASHBOARD ||============================== //

const PayoutDashboard = () => {
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
    const [data6, setData6] = useState([]);
    const [data7, setData7] = useState([]);

    const [loading, setLoading] = useState(false);

    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);
    const [series1, setSeries1] = useState([]);
    const [categories1, setCategories1] = useState([]);

    const [totalCollection, setTotalCollection] = useState();
    const [payinSummary, setPayinSummary] = useState();
    const [payoutSummary, setPayoutSummary] = useState();

    const [merchants, setMerchants] = React.useState([]);

    const [merchant, setMerchant] = React.useState();
    const [startDate, setStartDate] = React.useState(currentDate);
    const [endDate, setEndDate] = React.useState(currentDate);

    const [merchant1, setMerchant1] = React.useState();
    const [startDate1, setStartDate1] = React.useState(currentDate);
    const [endDate1, setEndDate1] = React.useState(currentDate);

    const [banks, setBanks] = React.useState([]);
    const [startDate2, setStartDate2] = React.useState(currentDate);
    const [endDate2, setEndDate2] = React.useState(currentDate);

    const [merchSummary, setMerchSummary] = React.useState([]);
    const [startDate3, setStartDate3] = React.useState(currentDate);
    const [endDate3, setEndDate3] = React.useState(currentDate);

    // const series = [
    //     {
    //         name: 'Success',
    //         type: 'column',
    //         color: '#008000',
    //         data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
    //     },
    //     {
    //         name: 'Failed',
    //         type: 'column',
    //         color: '#FF0000',
    //         data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    //     },
    //     {
    //         name: 'Initialising',
    //         type: 'column',
    //         color: '#ff9d3e',
    //         data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    //     },
    //     {
    //         name: 'Pending',
    //         type: 'column',
    //         color: '#0288d1',
    //         data: [20, 29, 37, 36, 44, 45, 50, 58]
    //     },
    //     {
    //         name: 'Processing',
    //         type: 'column',
    //         color: '#ffe57f',
    //         data: [20, 29, 37, 36, 44, 45, 50, 58]
    //     }
    // ];

    const options = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },
        // title: {
        //     text: 'XYZ - Stock Analysis (2009 - 2016)',
        //     align: 'left',
        //     offsetX: 110
        // },
        xaxis: {
            categories
        },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                labels: {
                    style: {
                        colors: '#008FFB'
                    }
                },
                title: {
                    text: 'Count',
                    style: {
                        color: '#008FFB'
                    }
                },
                tooltip: {
                    enabled: true
                }
            }
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            }
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }
    };

    const options1 = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },
        // title: {
        //     text: 'XYZ - Stock Analysis (2009 - 2016)',
        //     align: 'left',
        //     offsetX: 110
        // },
        xaxis: {
            categories: categories1
        },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                labels: {
                    style: {
                        colors: '#008FFB'
                    }
                },
                title: {
                    text: 'Count',
                    style: {
                        color: '#008FFB'
                    }
                },
                tooltip: {
                    enabled: true
                }
            }
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            }
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }
    };

    const fetchDataFromAPI = async () => {
        // try {
        //     fetchPayoutDetailsByPG('CLICKPAY').then((result) => {
        //         setData6(result?.data || []);
        //     });
        // } catch (error) {
        //     console.error(error);
        // }
        // try {
        //     fetchPayoutDetailsByPG('LINESPAY').then((result) => {
        //         setData7(result?.data || []);
        //     });
        // } catch (error) {
        //     console.error(error);
        // }
        // try {
        //     console.log('PAISAORDER');
        //     fetchPayoutDetailsByPG('PAISAORDER').then((result) => {
        //         setData2(result?.data || []);
        //     });
        // } catch (error) {
        //     console.error(error);
        // }

        // try {
        //     fetchPayoutDetailsByPG('PAYFAST').then((result) => {
        //         setData5(result?.data || []);
        //     });
        // } catch (error) {
        //     console.error(error);
        // }
        // try {
        //     console.log('INDIAPAY');
        //     fetchPayoutDetailsByPG('INDIAPAY').then((result) => {
        //         setData1(result?.data || []);
        //     });
        // } catch (error) {
        //     console.error(error);
        // }

        // try {
        //     console.log('SAFEXPAY');
        //     fetchPayoutDetailsByPG('SAFEXPAY').then((result) => {
        //         setData(result?.data || []);
        //     });
        // } catch (error) {
        //     console.error(error);
        // }
        // try {
        //     fetchPayoutDetailsByPG('ZWITCHPAY').then((result) => {
        //         setData4(result?.data || []);
        //     });
        // } catch (error) {
        //     console.error(error);
        // }
        // try {
        //     fetchPayoutDetailsByPG('TAPPAY').then((result) => {
        //         setData3(result?.data || []);
        //     });
        //     // setData3(result?.data || []);
        // } catch (error) {
        //     console.error(error);
        // }
        try {
            const result = await fetchTotalCollection();
            setTotalCollection(result?.data || []);
        } catch (error) {
            console.error(error);
        }
        try {
            setLoading(true);
            const result = await fetchDashboardData();
            setLoading(false);
            const series = result?.series?.map((s) => {
                s.fillColor = s.color;
                return s;
            });
            setSeries(series || []);
            setCategories(result?.category || []);
            // setData(result?.data || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
        try {
            setLoading(true);
            const result = await fetchDashboardDataPayout();
            setLoading(false);
            const series = result?.series?.map((s) => {
                s.fillColor = s.color;
                return s;
            });
            setSeries1(series || []);
            setCategories1(result?.category || []);
            // setData(result?.data || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
        try {
            let result = await fetchActiveMerchants();
            result = result?.map((r) => ({
                id: r.merchantId || '',
                label: r.name || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    const getPayinSummary = async (merchant, startDate, endDate) => {
        setLoading(true);
        try {
            const result = await fetchPayinSummary(merchant?.id || null, startDate, endDate);
            setLoading(false);
            setPayinSummary(result?.data || []);
        } catch (error) {
            setLoading(false);

            console.error(error);
        }
    };

    const getPayoutSummary = async (merchant, startDate, endDate) => {
        setLoading(true);
        try {
            const result = await fetchPayoutSummary(merchant?.id || null, startDate, endDate);
            setLoading(false);

            setPayoutSummary(result?.data || []);
        } catch (error) {
            setLoading(false);

            console.error(error);
        }
    };

    const fetchBankDetails = async (startDate, endDate) => {
        try {
            const result = await fetchBankAmount(startDate, endDate);
            setBanks(result);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchMerchSummaryDetails = async (startDate, endDate) => {
        try {
            const result = await fetchMerchSummary(startDate, endDate);
            setMerchSummary(result);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI();
    }, []);

    React.useEffect(() => {
        getPayinSummary(merchant, startDate, endDate);
    }, [merchant, startDate, endDate]);

    React.useEffect(() => {
        getPayoutSummary(merchant1, startDate1, endDate1);
    }, [merchant1, startDate1, endDate1]);

    React.useEffect(() => {
        fetchBankDetails(startDate2, endDate2);
    }, [startDate2, endDate2]);

    React.useEffect(() => {
        fetchMerchSummaryDetails(startDate3, endDate3);
    }, [startDate3, endDate3]);

    return (
        <>
            <MainCard title="Merchant Managment Dashboard" content={false}>
                {loading && <Loader />}
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={3} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                            <HoverDataCard
                                title="TODAY'S TOTAL PAYIN"
                                iconPrimary={CurrencyRupee}
                                primary={totalCollection?.totalPayin || 0}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={3} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                            <HoverDataCard
                                title="TODAY'S TOTAL PAYOUT"
                                iconPrimary={CurrencyRupee}
                                primary={totalCollection?.totalPayout || 0}
                            />
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 0px' }}>
                            <HoverDataCard title="TOTAL PAYOUT BALANCE" iconPrimary={CurrencyRupee} primary={0} />
                        </Card>
                    </Grid> */}
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="Transaction Summary" content={false}>
                <CardContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={startDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const stDate = dayjs(dt).format('YYYY-MM-DD');
                                    setStartDate(stDate);
                                }}
                                maxDate={ISTDate}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="Start Date"
                            />
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={endDate}
                                minDate={startDate}
                                maxDate={ISTDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const enDate = dayjs(dt).format('YYYY-MM-DD');
                                    setEndDate(enDate);
                                }}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="End Date"
                            />
                        </LocalizationProvider>
                        <Autocomplete
                            id="merchantId"
                            className="merchantId-select"
                            name="merchantId"
                            options={merchants?.length ? merchants : []}
                            onChange={(e, value) => {
                                setMerchant(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e, value) => {
                                        setMerchant(value);
                                    }}
                                    size="small"
                                    style={{ width: 200 }}
                                    label="Merchant Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </div>
                </CardContent>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={2.5} sm={3}>
                        <Card style={{ padding: 0, border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                            <HoverDataCard
                                title={`SUCCESS (${payinSummary?.find((p) => p.transactionStatus === 'SUCCESS')?.count || 0}) | ${
                                    payinSummary?.find((p) => p.transactionStatus === 'SUCCESS')?.percentage || 0
                                }% `}
                                iconPrimary={CurrencyRupee}
                                primary={`${payinSummary?.find((p) => p.transactionStatus === 'SUCCESS')?.amount || 0}`}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={2.5} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 0px' }}>
                            <HoverDataCard
                                title={`PROCESSING (${payinSummary?.find((p) => p.transactionStatus === 'PROCESSING')?.count || 0}) | ${
                                    payinSummary?.find((p) => p.transactionStatus === 'PROCESSING')?.percentage || 0
                                }% `}
                                iconPrimary={CurrencyRupee}
                                primary={`${payinSummary?.find((p) => p.transactionStatus === 'PROCESSING')?.amount || 0}`}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={2.5} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 0px' }}>
                            <HoverDataCard
                                title={`PENDING (${payinSummary?.find((p) => p.transactionStatus === 'PENDING')?.count || 0}) | ${
                                    payinSummary?.find((p) => p.transactionStatus === 'PENDING')?.percentage || 0
                                }% `}
                                iconPrimary={CurrencyRupee}
                                primary={`${payinSummary?.find((p) => p.transactionStatus === 'PENDING')?.amount || 0}`}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={2.5} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 0px' }}>
                            <HoverDataCard
                                title={`INITIALIZING (${payinSummary?.find((p) => p.transactionStatus === 'INITIALIZING')?.count || 0}) | ${
                                    payinSummary?.find((p) => p.transactionStatus === 'INITIALIZING')?.percentage || 0
                                }% `}
                                iconPrimary={CurrencyRupee}
                                primary={`${payinSummary?.find((p) => p.transactionStatus === 'INITIALIZING')?.amount || 0}`}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={2} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 0px' }}>
                            <HoverDataCard
                                title={`FAILED (${payinSummary?.find((p) => p.transactionStatus === 'FAILED')?.count || 0}) | ${
                                    payinSummary?.find((p) => p.transactionStatus === 'FAILED')?.percentage || 0
                                }% `}
                                iconPrimary={CurrencyRupee}
                                primary={`${payinSummary?.find((p) => p.transactionStatus === 'FAILED')?.amount || 0}`}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="Transaction Hour Chart" content={false}>
                <div style={{ margin: 20 }}>
                    <ReactApexChart options={options} series={series} type="line" height={350} />
                </div>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="Payout Summary" content={false}>
                <CardContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={startDate1}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const stDate = dayjs(dt).format('YYYY-MM-DD');
                                    setStartDate1(stDate);
                                }}
                                maxDate={ISTDate}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="Start Date"
                            />
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={endDate1}
                                minDate={startDate1}
                                maxDate={ISTDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const enDate = dayjs(dt).format('YYYY-MM-DD');
                                    setEndDate1(enDate);
                                }}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="End Date"
                            />
                        </LocalizationProvider>
                        <Autocomplete
                            id="merchantId"
                            className="merchantId-select"
                            name="merchantId"
                            options={merchants?.length ? merchants : []}
                            onChange={(e, value) => {
                                setMerchant1(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e, value) => {
                                        setMerchant1(value);
                                    }}
                                    size="small"
                                    style={{ width: 200 }}
                                    label="Merchant Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </div>
                </CardContent>
                {/* "INITIALIZING" "PROCESSING" "PENDING" "SUCCESS" "FAILED" */}
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={2.5} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                            <HoverDataCard
                                title={`SUCCESS (${payoutSummary?.find((p) => p.transactionStatus === 'SUCCESS')?.count || 0}) | ${
                                    payoutSummary?.find((p) => p.transactionStatus === 'SUCCESS')?.percentage || 0
                                }% `}
                                iconPrimary={CurrencyRupee}
                                primary={`${payoutSummary?.find((p) => p.transactionStatus === 'SUCCESS')?.amount || 0}`}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={2.5} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 0px' }}>
                            <HoverDataCard
                                title={`PENDING (${payoutSummary?.find((p) => p.transactionStatus === 'PENDING')?.count || 0}) | ${
                                    payoutSummary?.find((p) => p.transactionStatus === 'PENDING')?.percentage || 0
                                }% `}
                                iconPrimary={CurrencyRupee}
                                primary={`${payoutSummary?.find((p) => p.transactionStatus === 'PENDING')?.amount || 0}`}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={2.5} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 0px' }}>
                            <HoverDataCard
                                title={`INITIALIZING (${
                                    payoutSummary?.find((p) => p.transactionStatus === 'INITIALIZING')?.count || 0
                                }) | ${payoutSummary?.find((p) => p.transactionStatus === 'INITIALIZING')?.percentage || 0}% `}
                                iconPrimary={CurrencyRupee}
                                primary={`${payoutSummary?.find((p) => p.transactionStatus === 'INITIALIZING')?.amount || 0}`}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={2} sm={3}>
                        <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 0px' }}>
                            <HoverDataCard
                                title={`FAILED (${payoutSummary?.find((p) => p.transactionStatus === 'FAILED')?.count || 0}) | ${
                                    payoutSummary?.find((p) => p.transactionStatus === 'FAILED')?.percentage || 0
                                }% `}
                                iconPrimary={CurrencyRupee}
                                primary={`${payoutSummary?.find((p) => p.transactionStatus === 'FAILED')?.amount || 0}`}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="Transaction Payout Chart" content={false}>
                <div style={{ margin: 20 }}>
                    <ReactApexChart options={options1} series={series1} type="line" height={350} />
                </div>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="Merchant Summary" content={false}>
                <CardContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={startDate3}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const stDate = dayjs(dt).format('YYYY-MM-DD');
                                    setStartDate3(stDate);
                                }}
                                maxDate={ISTDate}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="Start Date"
                            />
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={endDate3}
                                minDate={startDate3}
                                maxDate={ISTDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const enDate = dayjs(dt).format('YYYY-MM-DD');
                                    setEndDate3(enDate);
                                }}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="End Date"
                            />
                        </LocalizationProvider>
                    </div>
                </CardContent>
                <Grid container spacing={gridSpacing}>
                    <table style={{ width: '60%', margin: '10px 40px 20px 40px' }}>
                        <tr>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Merchant</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Payin Amount</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Payin Charge</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Payin Affiliate Charge</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Payout Amount</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Payout Charge</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Payout Affiliate Charge</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Payin AR</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Payout AR</th>
                        </tr>
                        {merchSummary?.map((b) => (
                            <tr>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>
                                    {b.merchantName}
                                    {/* ({b.merchantId}) */}
                                </td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.totalPayinAmount}</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.totalPayinCharge}</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.totalPayinAffiliateCharge}</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.totalPayoutAmount}</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.totalPayoutCharge}</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.totalPayoutAffiliateCharge}</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.payInARratio}%</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.payOutARRatio}%</td>
                            </tr>
                        ))}
                    </table>
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="Banks" content={false}>
                <CardContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={startDate2}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const stDate = dayjs(dt).format('YYYY-MM-DD');
                                    setStartDate2(stDate);
                                }}
                                maxDate={ISTDate}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="Start Date"
                            />
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={endDate2}
                                minDate={startDate2}
                                maxDate={ISTDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const enDate = dayjs(dt).format('YYYY-MM-DD');
                                    setEndDate2(enDate);
                                }}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="End Date"
                            />
                        </LocalizationProvider>
                    </div>
                </CardContent>
                <Grid container spacing={gridSpacing}>
                    <table style={{ width: '50%', margin: '10px 40px 20px 40px' }}>
                        <tr>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Bank</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>CR</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>DR</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Credit Limit</th>
                            <th style={{ border: '1px solid lightgray', padding: 8, textAlign: 'left' }}>Debit Limit</th>
                        </tr>
                        {banks?.map((b) => (
                            <tr>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>
                                    {b.accountHolderName} ({b.bankName})
                                </td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.crTotal}</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.drTotal}</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.creditLimit}</td>
                                <td style={{ border: '1px solid lightgray', padding: 8 }}>{b.debitLimit}</td>
                            </tr>
                        ))}
                    </table>
                </Grid>
            </MainCard>

            {/* <MainCard style={{ marginTop: 20 }} title="CLICKPAY" content={false}>
                <Grid container spacing={gridSpacing}>
                    {data6?.map((s) => (
                        <Grid item xs={6} lg={4} sm={4}>
                            <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                                <HoverDataCard title={s?.label} iconPrimary={CurrencyRupee} primary={s?.amount || 0} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="LINESPAY" content={false}>
                <Grid container spacing={gridSpacing}>
                    {data7?.map((s) => (
                        <Grid item xs={6} lg={4} sm={4}>
                            <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                                <HoverDataCard title={s?.label} iconPrimary={CurrencyRupee} primary={s?.amount || 0} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="PAISAORDER" content={false}>
                <Grid container spacing={gridSpacing}>
                    {data2?.map((s) => (
                        <Grid item xs={6} lg={4} sm={4}>
                            <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                                <HoverDataCard title={s?.label} iconPrimary={CurrencyRupee} primary={s?.amount || 0} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="PAYFAST" content={false}>
                <Grid container spacing={gridSpacing}>
                    {data5?.map((s) => (
                        <Grid item xs={6} lg={4} sm={4}>
                            <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                                <HoverDataCard title={s?.label} iconPrimary={CurrencyRupee} primary={s?.amount || 0} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="INDIAPAY" content={false}>
                <Grid container spacing={gridSpacing}>
                    {data1?.map((s) => (
                        <Grid item xs={6} lg={4} sm={4}>
                            <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                                <HoverDataCard title={s?.label} iconPrimary={CurrencyRupee} primary={s?.amount || 0} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="SAFEX" content={false}>
                <Grid container spacing={gridSpacing}>
                    {data?.map((s) => (
                        <Grid item xs={6} lg={4} sm={4}>
                            <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                                <HoverDataCard title={s?.label} iconPrimary={CurrencyRupee} primary={s?.amount || 0} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="ZWITCHPAY" content={false}>
                <Grid container spacing={gridSpacing}>
                    {data4?.map((s) => (
                        <Grid item xs={6} lg={4} sm={4}>
                            <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                                <HoverDataCard title={s?.label} iconPrimary={CurrencyRupee} primary={s?.amount || 0} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </MainCard>

            <MainCard style={{ marginTop: 20 }} title="TAPPAY" content={false}>
                <Grid container spacing={gridSpacing}>
                    {data3?.map((s) => (
                        <Grid item xs={6} lg={4} sm={4}>
                            <Card style={{ border: '1px solid lightgray', margin: '20px 5px 20px 20px' }}>
                                <HoverDataCard title={s?.label} iconPrimary={CurrencyRupee} primary={s?.amount || 0} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </MainCard> */}

            {loading && <Loader />}
        </>
    );
};

export default PayoutDashboard;
