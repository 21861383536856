import * as React from 'react';

// material-ui
import { CardContent } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';

import { fetchMerchantDetails } from 'utils/api';
import { useParams } from 'react-router-dom';
import MerchantBanks from './MerchantBanks';
import PayInMeta from './PayInMeta';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyAll } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';

// ==============================|| MERCHANT PAYIN ||============================== //

const PayIn = () => {
    const { merchantId } = useParams();
    const dispatch = useDispatch();

    const [merchantDetails, setMerchantDetails] = React.useState('');
    const [refetch, setRefetch] = React.useState('');
    const [copy, setCopy] = React.useState(false);

    React.useEffect(() => {
        if (copy) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Copied!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(closeSnackbar);
            setCopy(false);
        }
    }, [copy]);

    const fetchDataFromAPI = async (id) => {
        try {
            const result = await fetchMerchantDetails(id);
            setMerchantDetails(result);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (merchantId) {
            fetchDataFromAPI(merchantId);
        }
    }, [merchantId]);

    return (
        <>
            <MainCard
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{merchantDetails?.name} PayIn</div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                            <div style={{ display: 'flex' }}>
                                <div>MID: {merchantDetails?.merchantId}</div>
                                <CopyToClipboard onCopy={() => setCopy(true)} text={merchantDetails?.merchantId}>
                                    <CopyAll style={{ fontSize: 20, cursor: 'pointer', marginLeft: 3 }} />
                                </CopyToClipboard>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div title={merchantDetails?.secret}>Secret: {merchantDetails?.secret?.substring(0, 20)}...</div>
                                <CopyToClipboard onCopy={() => setCopy(true)} text={merchantDetails?.secret}>
                                    <CopyAll style={{ fontSize: 20, cursor: 'pointer', marginLeft: 3 }} />
                                </CopyToClipboard>
                            </div>
                            <div>Status: {merchantDetails?.merchantStatus}</div>
                        </div>
                    </div>
                }
                content={false}
            >
                <CardContent>
                    <MerchantBanks id={merchantId} refetch={refetch} />
                </CardContent>
            </MainCard>

            <MainCard style={{ marginTop: 10 }} title="PAYIN META" content={false}>
                <PayInMeta id={merchantId} setRefetch={setRefetch} />
            </MainCard>
        </>
    );
};

export default PayIn;
