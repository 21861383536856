import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MerchantUser from 'views/dashboard/MerchantUser';
import PayIn from 'views/dashboard/AgentMerchant/PayIn';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/AgentViews/dashboard/Default')));
// const DashboardMerchant = Loadable(lazy(() => import('views/AgentViews/dashboard/Merchant')));

// pay in pay out pages
const Transaction = Loadable(lazy(() => import('views/pay-in-pay-out/Transaction')));
const BankTransaction = Loadable(lazy(() => import('views/pay-in-pay-out/BankTransaction')));
const BankTransactionDetails = Loadable(lazy(() => import('views/pay-in-pay-out/BankTransaction/Details')));
const Payout = Loadable(lazy(() => import('views/pay-in-pay-out/Payout')));
const ManualBank = Loadable(lazy(() => import('views/pay-in-pay-out/Bank')));
const DashboardMerchant = Loadable(lazy(() => import('views/dashboard/AgentMerchant')));

// support pages
const Reports = Loadable(lazy(() => import('views/AgentViews/support/Reports')));

const childrenRoutes = [
    {
        path: '/default',
        element: <DashboardDefault />
    },
    {
        path: '/payment/transaction',
        element: <Transaction />
    },
    {
        path: '/dashboard/merchant',
        element: <DashboardMerchant />
    },
    {
        path: '/merchant/payin/:merchantId',
        element: <PayIn />
    },
    {
        path: '/dashboard/merchant-user',
        element: <MerchantUser />
    },
    {
        path: '/payment/bank-transaction',
        element: <BankTransaction />
    },
    {
        path: '/payment/bank-transaction/:bankId',
        element: <BankTransactionDetails />
    },
    {
        path: 'support/reports',
        element: <Reports />
    },
    {
        path: '/payment/payout',
        element: <Payout />
    },
    {
        path: '/payment/bank',
        element: <ManualBank />
    }
];

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: childrenRoutes
};

export default AdminRoutes;
